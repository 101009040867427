* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* ------------------------------- Battlefield4 ------------------------------- */

body {
  margin: 0;
  padding: 0;
  z-index: 0;

  background-image: linear-gradient(rgba(0, 12, 19, .6), rgba(0, 12, 19, .6)), url(https://uploads-ssl.webflow.com/6013fff62154adaa4600f932/6038f70ef0141a2f22d50f58_menu__background.jpg);
  background-size: auto, cover;
}

.container {
  color: white;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
}

/* ------------------------------- Menu ------------------------------- */

.BF-menu {
  width: 100%;
  height: 0px;
  padding: 4vh 0 6vh 12vh;
  position: relative;
}

.menu-container {
  display: flex;
  justify-content: left;
}

.menu-btn {
  opacity: .8;
  color: #fff;
  text-align: left;
  margin-right: 16px;
  cursor: pointer;

  text-decoration: none;
  font-size: 16px;
  font-family: Rajdhani, sans-serif;
  font-weight: 500;
  line-height: 3.5vh;
  transition: text-shadow .1s cubic-bezier(.455, .03, .515, .955);
}

.menu-btn:hover {
  text-shadow: 0 0 5px rgba(255, 255, 255, .8);
}

.BF-menu .active-btn {
  border-bottom: 1.8px solid #fff;
  height: 3.7vh;
  opacity: 1;
  font-weight: 700;
}

.BF-menu .active-btn:hover {
  text-shadow: 0 0 5px rgba(255, 255, 255, .8);
}

.menu-bar {
  z-index: 1;
  width: 70vw;
  height: 1px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, .15) 73%, rgba(255, 255, 255, 0));
  position: absolute;
  top: 7.6vh;
  bottom: auto;
  left: 12vh;
  right: 0%;
}

/* ------------------------------- Side-Menu ------------------------------- */

.side-menu {
  z-index: 2;
  width: 1px;
  height: 100vh;
  background-color: rgba(255, 255, 255, .15);
  margin-left: 8vh;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.side-menu-container {
  z-index: 3;
  margin-top: 17vh;
  display: block;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
  overflow: visible;
}

.side-menu-btn {
  background-position: 2vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: rgba(0, 0, 0, 0);
  border-left: 3px solid rgba(0, 0, 0, 0);

  width: 6.3vh;
  height: 4.3vh;
  margin-bottom: 1.3vh;
  padding: 0;
  opacity: .5;
  justify-content: flex-start;
  text-decoration: none;
  align-items: center;
  transition: opacity .1s cubic-bezier(.455, .03, .515, .955);
  display: flex;
}

.side-menu-btn:hover {
  opacity: 1;
}

.side-menu-btn .popup {
  z-index: 5;
  background-color: #101010;
  flex: none;
  margin-left: 7vh;
  padding: 1vh 1vh .8vh;
}

.side-menu-btn .popup-text {
  color: #fff;
  text-align: center;
  letter-spacing: .07vh;
  text-transform: uppercase;
  font-family: Rajdhani, sans-serif;
  font-size: 1.3vh;
  font-weight: 500;
  line-height: 1.5vh;
  text-decoration: none;
}

.side-menu .footer-btns {
  position: fixed;
  left: 1vw;
  bottom: 1vh;
  z-index: 4;
}

.side-menu .footer-btns .popup {
  margin-left: -8vh;
}

.quit-container {
  z-index: 10000;
  background-color: rgba(16, 16, 16, .9);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.quit-text-w {
  margin-bottom: 5vh;
}

.quit-text {
  color: #fff;
  text-align: center;
  letter-spacing: .1vh;
  text-transform: uppercase;
  margin-bottom: 1vh;
  font-family: Rajdhani, sans-serif;
  font-size: 2.4vh;
  font-weight: 600;
  line-height: 3vh;
}

.quit-text-2 {
  color: rgba(255, 255, 255, .8);
  text-align: center;
  letter-spacing: .15vh;
  text-transform: none;
  font-family: Rajdhani, sans-serif;
  font-size: 1.3vh;
  font-weight: 600;
  line-height: 2vh;
}

.quit-buttons-w {
  display: flex;
}

.quit-button {
  color: #fff;
  text-align: center;
  letter-spacing: .1vh;
  text-transform: uppercase;
  border: 1px solid rgba(255, 255, 255, .3);
  margin-right: 1vh;
  padding: 1.5vh 3.5vh;
  font-family: Rajdhani, sans-serif;
  font-size: 1.6vh;
  font-weight: 600;
  line-height: 2vh;
  transition: all .1s;
}

.quit-button:hover {
  color: #101010;
  background-color: #fff;
}

.quit-button.tr-button--nomargin, .quit-button.quit-button--nomargin {
  margin-right: 0;
}

/* ------------------------------- Invite-Menu ------------------------------- */

.invite {
  z-index: 9995;
  width: 27vh;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 3.5vh;
  padding-left: 1.5vh;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
  transform: translate(19vh);
  transition-duration: 350ms;
  transform-style: preserve-3d;
}

.invite-header {
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1vh;
  display: flex;
}

.invite-header img {
  margin-right: 5px;
  padding-bottom: 2px;
}

.invite-line {
  height: 1px;
  opacity: 0;
  background-color: rgba(255, 255, 255, .1);
}

.invite-squad {
  margin-top: 1.2vh;
  margin-bottom: 1.5vh;
}

.invite-squad-icon {
  margin-right: .5vh;
}

.invite-squad-h {
  opacity: 0;
  color: #fff;
  letter-spacing: .08vh;
  text-transform: uppercase;
  font-family: Rajdhani, sans-serif;
  font-size: 1.5vh;
  font-weight: 600;
  line-height: 1.8vh;
}

.invite-join {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.invite-join-icon {
  height: 4.5vh;
  margin-right: 1vh;
}

.invite-join-icon.invite-join-icon--default {
  z-index: 1;
  position: relative;
}

.invite-join-icon.invite-join-icon--black {
  z-index: 2;
  margin-left: -1px;
  display: none;
}

.invite-join-h {
  opacity: 0;
  color: #fff;
  letter-spacing: .08vh;
  text-transform: capitalize;
  font-family: Rajdhani, sans-serif;
  font-size: 1.2vh;
  font-weight: 600;
  line-height: 1.6vh;
}

.invite-online {
  margin-top: 1.2vh;
  margin-bottom: 1.5vh;
}

.invite-online-icon {
  margin-right: .5vh;
}

.invite-online-h {
  opacity: 0;
  color: #fff;
  letter-spacing: .08vh;
  text-transform: uppercase;
  font-family: Rajdhani, sans-serif;
  font-size: 1.5vh;
  font-weight: 600;
  line-height: 1.8vh;
}

.invite-online-friend {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.invite-online-bar {
  width: 2px;
  height: 4.6vh;
  background-color: #23c13a;
  margin-right: .3vh;
}

.invite-friend-profile {
  height: 4.6vh;
}

.invite-friend-profile-w {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.invite-friend-info {
  height: 4.6vh;
  opacity: 0;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  padding-left: 1vh;
  display: flex;
  transition-duration: 300ms;
}

.invite-friend-name {
  color: #fff;
  letter-spacing: .08vh;
  text-transform: capitalize;
  font-family: Rajdhani, sans-serif;
  font-size: 1.2vh;
  font-weight: 600;
  line-height: 1.6vh;
}

.invite-friend-status {
  color: rgba(255, 255, 255, .7);
  letter-spacing: .08vh;
  text-transform: capitalize;
  font-family: Rajdhani, sans-serif;
  font-size: 1.2vh;
  font-weight: 600;
  line-height: 1.6vh;
}

.invite-offline {
  margin-top: 1.2vh;
  margin-bottom: 1.5vh;
}

.invite-offline-icon {
  margin-right: .5vh;
}

.invite-offline-h {
  opacity: 0;
  color: #fff;
  letter-spacing: .08vh;
  text-transform: uppercase;
  font-family: Rajdhani, sans-serif;
  font-size: 1.5vh;
  font-weight: 600;
  line-height: 1.8vh;
}

.invite-offline-friend {
  opacity: .4;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.invite-offline-bar {
  width: 2px;
  height: 4.6vh;
  background-color: #fff;
  margin-right: .3vh;
}