/*-------------------------------------- BF4 Header --------------------------------------*/

.bf4-header-container {
    z-index: 3;
    align-items: center;
    display: flex;
    position: absolute;
    top: 3vh;
    bottom: auto;
    left: auto;
    right: 10vh;
}


.bf4-level {
    width: 7vh;
    height: 7vh;
    border: 2px solid rgba(255, 255, 255, .1);
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    margin-right: 2vh;
    display: flex;
}

.bf4-level-bar {
    width: 7vh;
    height: 7vh;
    border: 2px solid #ee930e;
    border-style: solid solid none none;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.bf4-level-icon {
    width: 6vh;
    height: 6vh;
    display: block;
}

.bf4-level-flare {
    z-index: 3;
    background-image: url("https://uploads-ssl.webflow.com/6013fff62154adaa4600f932/6038d3f46cf89081fabbb194_level-flare.png");
    background-position: 50%;
    background-size: cover;
    display: none;
    position: absolute;
}

.bf4-level-shadow {
    border-radius: 100%;
    display: none;
    position: absolute;
    box-shadow: 0 0 20px rgba(255, 255, 255, .4);
}

.bf4-logo-icon {
    height: 3.4vh;
}

/*-------------------------------------- BF4 Soldier Page --------------------------------------*/

.bf4-content-w {
    padding-top: 3.5vh;
    overflow: visible;
}

.soldier-BFemblem {
    width: 38vh;
    display: inline-block;
    position: absolute;
    z-index: 1;

    top: 0;
    bottom: auto;
    left: auto;
    right: 35.5vh;
}

.soldier-content {
    z-index: 2;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 4vh;
    margin-left: 12vh;
    display: flex;
    position: relative;
}

.soldier-look {
    width: 33vh;
    flex: none;
    position: relative;
    top: -4vh;
}

.soldier-pic {
    width: 33vh;
    position: relative;
    left: 4vh;
}

.soldier-dogtags {
    position: absolute;
    bottom: 10vh;
}

.soldier-dogtags-left {
    z-index: 2;
    max-width: 80%;
    position: relative;
}

.soldier-dogtags-right {
    z-index: 1;
    max-width: 80%;
    position: relative;
    left: -88px;
}

.soldier-info {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 3vh;
    display: flex;
}

.soldier-level {
    width: 8vh;
    height: 8vh;
    border: 2px solid rgba(255, 255, 255, .1);
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    margin-right: 2vh;
    display: flex;
    position: relative;
}

.soldier-level-bar {
    width: 8vh;
    height: 8vh;
    border: 2px solid #ee930e;
    border-style: solid solid none none;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    margin-top: -1px;
    margin-left: -1px;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.soldier-level-icon {
    width: 7vh;
    height: 7vh;
}

.soldier-level-info-h {
    color: #fff;
    margin-bottom: 1vh;
    font-family: Rajdhani, sans-serif;
    font-size: 2.7vh;
    font-weight: 700;
    line-height: 3.5vh;
}

.soldier-level-stats {
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.soldier-level-no {
    width: 6vh;
    border: 1px solid rgba(255, 255, 255, .3);
    margin-right: 1.5vh;
}

.soldier-level-text {
    color: #fff;
    text-align: center;
    font-family: Rajdhani, sans-serif;
    font-size: 1.4vh;
    font-weight: 500;
    line-height: 1.8vh;
}

.soldier-level-current {
    color: #fff;
    margin-right: .5vh;
    font-family: Rajdhani, sans-serif;
    font-size: 1.4vh;
    font-weight: 500;
    line-height: 1.8vh;
}

.soldier-level-estimate {
    color: rgba(255, 255, 255, .5);
    margin-right: .5vh;
    font-family: Rajdhani, sans-serif;
    font-size: 1.4vh;
    font-weight: 500;
    line-height: 1.8vh;
}

.soldier-select {
    display: flex;
}

.soldier-menu-w {
    border-top: 1px solid rgba(255, 255, 255, .1);
    margin-right: 6vh;
}

.soldier-menu {
    width: 30vh;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    justify-content: space-between;
    align-items: center;
    padding-left: 1.7vh;
    padding-right: 1.7vh;
    text-decoration: none;
    display: flex;
}

.soldier-menu-h {
    color: #fff;
    letter-spacing: .07vh;
    text-transform: uppercase;
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-family: Rajdhani, sans-serif;
    font-size: 1.4vh;
    font-weight: 600;
    line-height: 1.8vh;
    text-decoration: none;
}

.soldier-menu-h:hover {
    text-decoration: none;
}

.soldier-menu-stats {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 1vh;
    margin-bottom: 1.2vh;
    display: flex;
}

.soldier-menu-stats-h {
    color: #fff;
    text-align: right;
    letter-spacing: .08vh;
    margin-bottom: .3vh;
    font-family: Rajdhani, sans-serif;
    font-size: 1.25vh;
    font-weight: 600;
    line-height: 1.8vh;
    text-decoration: none;
}

.soldier-menu-stats-bar {
    position: relative;
}

.soldier-menu-stats-bar-black {
    z-index: 1;
    width: 7vh;
    height: 4px;
    background-color: rgba(16, 16, 16, .5);
    position: relative;
}

.soldier-stats-bar-orange {
    z-index: 2;
    width: 4.5vh;
    height: 4px;
    background-color: #ee930e;
    position: absolute;
}

.soldier-stats-w {
    align-items: flex-start;
    margin-bottom: 5vh;
    display: flex;
}

.soldier-stats {
    margin-right: 9vh;
}

.soldier-stats-h {
    color: #fff;
    text-transform: uppercase;
    font-family: Rajdhani, sans-serif;
    font-size: 1.5vh;
    font-weight: 500;
    line-height: 1.8vh;
}

.soldier-stats-percent {
    color: #fff;
    text-transform: uppercase;
    font-family: Rajdhani, sans-serif;
    font-size: 2.8vh;
    font-weight: 700;
    line-height: 3.5vh;
}

.soldier-top-stats-w {
    grid-column-gap: 6px;
    grid-row-gap: 8px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.soldier-top-stats {
    width: 24vh;
    height: 15vh;
    background-color: rgba(16, 16, 16, .5);
    padding: 1vh;
    text-decoration: none;
    position: relative;
}

.soldier-top-stats-h {
    color: rgba(255, 255, 255, .7);
    letter-spacing: .15vh;
    text-transform: uppercase;
    font-family: Rajdhani, sans-serif;
    font-size: 1.3vh;
    font-weight: 600;
    line-height: 1.8vh;
}

.soldier-top-stats-info {
    position: absolute;
    bottom: 1vh;
    left: 1vh;
}

.soldier-top-stats-weapon {
    color: #fff;
    letter-spacing: .05vh;
    text-transform: capitalize;
    font-family: Rajdhani, sans-serif;
    font-size: 1.4vh;
    font-weight: 600;
    line-height: 1.8vh;
}

.soldier-top-stats-weapon-s {
    color: rgba(255, 255, 255, .5);
    letter-spacing: .05vh;
    text-transform: none;
    font-family: Rajdhani, sans-serif;
    font-size: 1.3vh;
    font-weight: 600;
    line-height: 1.8vh;
}

.soldier-tank-white {
    z-index: 1;
    height: 8vh;
    position: absolute;
    left: 10%;
}

.soldier-tank-black {
    z-index: 2;
    height: 8vh;
    display: none;
    position: absolute;
    left: 10%;
}

.soldier-acw-white {
    z-index: 1;
    height: 8vh;
    margin-left: 0;
    position: absolute;
}

.soldier-acw-black {
    z-index: 2;
    height: 8vh;
    display: none;
    position: absolute;
}

.soldier-engineer-white {
    z-index: 1;
    height: 6vh;
    margin-left: 30%;
    position: absolute;
    top: 25%;
    bottom: 35%;
    left: 10%;
    right: 0%;
}

.soldier-engineer-black {
    z-index: 2;
    height: 6vh;
    margin-left: 28%;
    display: none;
    position: absolute;
    top: 25%;
    bottom: 35%;
    left: 10%;
}

.soldier-m9-white {
    z-index: 1;
    height: 8vh;
    position: absolute;
    left: 25%;
}

.soldier-m9-black {
    z-index: 2;
    height: 8vh;
    display: none;
    position: absolute;
    left: 25%;
}

.loader-container {
    position: absolute;
    top: 40%;
    left: 35%;
}